// Hold the bird breeds needed for the patient forms
export const bird_breeds = [
  "Adelie Penguin",
  "African Grey Parrot",
  "African Penguin",
  "Albatross",
  "Amazon Parrot",
  "American Robin",
  "Avocet",
  "Bald Eagle",
  "Barn Owl",
  "Barn Swallow",
  "Barred Owl",
  "Bearded Vulture",
  "Bird",
  "Bird Of Paradise",
  "Blackpoll Warbler",
  "Blue grosbeak",
  "Blue Jay",
  "Bobolink",
  "Booby",
  "Budgerigar",
  "Burrowing Owl",
  "Carolina Parakeet",
  "Cassowary",
  "Chickadee",
  "Chicken",
  "Chinese Geese",
  "Chinstrap Penguin",
  "Cockatiel",
  "Cockatoo",
  "Common Buzzard",
  "Common Loon",
  "Common Raven",
  "Cooper’s Hawk",
  "Crane",
  "Crested Penguin",
  "Crow",
  "Cuckoo",
  "Dodo",
  "Duck",
  "Eagle",
  "Eastern Bluebird",
  "Eastern Phoebe",
  "Eclectus Parrot",
  "Elegant Tern",
  "Emperor Penguin",
  "Emu",
  "Eurasian Nuthatch",
  "European Robin",
  "Falcon",
  "Ferruginous Hawk",
  "Flamingo",
  "Frigatebird",
  "Galapagos Penguin",
  "Gentoo Penguin",
  "Golden Masked Owl",
  "Golden Oriole",
  "Goose",
  "Gouldian Finch",
  "Great Blue Heron",
  "Great Crested Flycatcher",
  "Green Bee-Eater",
  "Grouse",
  "Guinea Fowl",
  "Harpy Eagle",
  "Harris Hawk",
  "Hawaiian Crow",
  "Heron",
  "Honey Buzzard",
  "Hoopoe",
  "Hornbill",
  "House Finch",
  "House wren",
  "Humboldt Penguin",
  "Hummingbird",
  "Ibis",
  "Ivory-billed woodpecker",
  "Jacana",
  "Jackdaw",
  "Kakapo",
  "Keel-Billed Toucan",
  "King Penguin",
  "Kingfisher",
  "Kiwi",
  "Kookaburra",
  "Kori Bustard",
  "Little Penguin",
  "Long-Eared Owl",
  "Long-Tailed Tit",
  "Lorikeet",
  "Lyrebird",
  "Macaroni Penguin",
  "Macaw",
  "Magellanic Penguin",
  "Magpie",
  "Mallard",
  "Marabou Stork",
  "Mississippi Kite",
  "Mountain Bluebird",
  "Mourning Dove",
  "Myna Bird",
  "Nicobar pigeon",
  "Nightingale",
  "Northern Cardinal",
  "Ostrich",
  "Owl",
  "Parakeet",
  "Parrot",
  "Parrotlet",
  "Peacock",
  "Pelican",
  "Penguin",
  "Peregrine Falcon",
  "Pigeon",
  "Pileated Woodpecker",
  "Puffin",
  "Purple Finch",
  "Quail",
  "Quetzal",
  "Red Finch",
  "Red-winged blackbird",
  "Ringed Kingfisher",
  "Robin",
  "Rockhopper Penguin",
  "Rooster",
  "Rose-breasted Grosbeak",
  "Roseate Spoonbill",
  "Royal Penguin",
  "Ruby-Throated Hummingbird",
  "Scarlet Macaw",
  "Sea Eagle",
  "Seagull",
  "Senegal Parrot",
  "Shoebill Stork",
  "Silkie Chicken",
  "Snowy Owl",
  "Song Sparrow",
  "Song Thrush",
  "Sparrow",
  "Spixs Macaw",
  "Stork",
  "Swan",
  "Tawny Owl",
  "Thrush",
  "Toucan",
  "Tree swallow",
  "Tropicbird",
  "Turkey",
  "Uguisu",
  "Umbrellabird",
  "Ural owl",
  "Vulture",
  "Wandering Albatross",
  "White-Tailed Eagle",
  "Whooping Crane",
  "Willow Warbler",
  "Woodpecker",
  "Yellow-Eyed Penguin",
  "Zebra Finch"
].sort()