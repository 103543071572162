// Hold the rodent breeds needed for the patient forms
export const rodent_breeds = [
  "Hamster",
  "Rat",
  "Mouse",
  "Guinea Pig",
  "Gerbil",
  "Chinchilla",
  "Squirrel",
  "Rabbit",
  "Hare",
  "Degu",
  "Sugar Glider",
  "Ferret",
  "Shrew",
  "Skinny Pig"
].sort()